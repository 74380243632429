.buy-day-passes-box-wrapper {
  margin-bottom: 3rem;
  font-family: $fontOpenSans;
  font-style: normal;
  color: $colorBlack;

  .buy-day-passes-box {
    //background: #f7f7f7;
    //box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    //border-radius: 2px;
    background: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    .box-header {
      //background-color: $colorBlack;
      color: $colorBlack;
      font-weight: bold;
      font-size: pxToRem(20);
      line-height: pxToRem(29);
      text-align: center;
      letter-spacing: 0.02rem;
      //padding: 0.6rem 0;
      margin: 2rem 0;
      &:first-child {
        margin-top: 0;
        padding-top: 2rem;
      }
      .remove-guest-button {
        cursor: pointer;
      }
    }
    .separator {
      border-bottom: 1px solid #797979;
      height: 1px;
      margin-bottom: 2rem;
      padding: 0 0.0625rem;
    }
    .box-content {
      width: 80%;
      margin: 0 auto;
      color: $colorBlack;
      font-weight: bold;
      .product-container {
        margin: 2rem 0 3rem;
        justify-content: space-between;
        // > div {
        //   flex: 0 0 50%;
        //   max-width: 50%;
        // }
        .product {
          .num-days {
            font-size: pxToRem(18);
            line-height: pxToRem(27);
          }
          .price {
            font-size: pxToRem(32);
            line-height: 149.8%;
          }
          .desc {
            font-size: pxToRem(14);
            line-height: pxToRem(21);
          }
        }
        .qty {
          // display: flex;
          // justify-content: space-between;
          max-width: 10rem;
          // margin: 0 auto;
          // padding-left: 4%;
          .row {
            > div {
              text-align: center;
            }
          }
          img {
            // display: block;
            cursor: pointer;
          }
          span {
            // display: block;
            font-size: pxToRem(32);
            line-height: pxToRem(48);
            text-align: center;
          }
        }
      }
    }
    .total {
      &.grand-total {
        margin: 2.5rem 0;
      }
      &.sub-total {
        margin-bottom: 2.5rem;
      }
      font-weight: bold;
      text-align: center;
      .total-text {
        font-size: pxToRem(18);
        line-height: pxToRem(27);
      }
      .total-price {
        font-size: pxToRem(44);
        line-height: 3rem;
        &:after {
          content: ".00";
          font-size: pxToRem(18);
          line-height: pxToRem(27);
          vertical-align: top;
          top: 4px;
          left: 2px;
          position: relative;
        }
      }
      .savings {
        font-size: pxToRem(14);
        line-height: pxToRem(29);
        color: $colorLightBlue;
      }
    }
    .checkbox-subscribe,
    .checkbox-terms {
      font-weight: normal;
    }
    .checkbox-subscribe {
      margin-top: 1rem;
    }
    .add-guest {
      text-align: center;
      .add-guest-button {
        background-color: #dfdfdf;
        padding: pxToRem(18) 0;
        margin: 2rem 0;
        cursor: pointer;
        .add-guest-button-text {
          padding-left: pxToRem(34);
          background: url("../../svg/icon-add-guest.svg") no-repeat top left;
          color: $colorBlack;
          font-weight: bold;
          font-size: pxToRem(14);
          line-height: pxToRem(21);
          display: inline-block;
        }
      }
    }
    .button {
      padding: 2rem 0;
      text-align: center;
      button {
        background-color: $colorRed;
        font-family: $fontRoboto;
        font-weight: bold;
        font-size: pxToRem(18);
        line-height: pxToRem(32);
        letter-spacing: 0.1rem;
        color: $colorWhite;
        padding: 1.1rem 0;
        border: 0;
        width: 100%;
      }
    }
  }
}
