h1,
h2,
h3 {
  font-family: $fontStagSans;
  font-size: pxToRem(34);
  line-height: pxToRem(39);
  letter-spacing: 0.045rem;
  margin-bottom: 1.8rem;
}
a {
  color: $colorBlack;
  text-decoration: underline;
  &:hover {
    color: $colorBlack;
  }
}
p,
ul {
  font-family: $fontRoboto;
}
ul {
  padding: 0;
  list-style: none;
  margin-bottom: 2.5rem;
  &.select-passes-points {
    margin: 2.5rem 0;
  }
  li {
    margin-bottom: 1rem;
    &.icon-alarm {
      padding-left: pxToRem(34);
      background: url("../../svg/icon-alarm.svg") no-repeat top left;
    }
    &.icon-money-off {
      padding-left: pxToRem(34);
      background: url("../../svg/icon-money-off.svg") no-repeat top left;
    }
    &.icon-tick {
      padding-left: pxToRem(34);
      background: url("../../svg/icon-tick.svg") no-repeat top left;
    }
    &.icon-calendar {
      padding-left: pxToRem(34);
      background: url("../../svg/icon-calendar.svg") no-repeat top left;
    }
  }
}
.button-link {
  background-color: $colorWhite;
  font-family: $fontOpenSans;
  font-weight: bold;
  text-align: center;
  display: block;
  font-size: pxToRem(18);
  line-height: pxToRem(32);
  border: 1px solid $colorLightBlue;
  box-sizing: border-box;
  color: $colorLightBlue;
  padding: 1.1rem 0;
  margin-bottom: 1rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $colorLightBlue;
  }
  &.solid {
    background-color: $colorLightBlue;
    color: $colorWhite;
    &:hover {
      color: $colorWhite;
      text-decoration: none;
    }
  }
}
