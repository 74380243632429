header {
  position: relative;
  z-index: 1001;
  background: $colorBlack;
  margin-bottom: 2rem;

  @media (min-width: $breakpointLg) {
    width: 100%;
    color: $colorWhite;
  }

  .wrapper {
    height: pxToRem(60);
    padding-top: 0;
    @media (min-width: $breakpointLg) {
      height: pxToRem(85);
    }

    a.logo {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1002;
      background: $colorRed;
      max-width: pxToRem(75);
      @media (min-width: $breakpointLg) {
        width: pxToRem(220);
        max-width: 100%;
        height: pxToRem(95);
        padding: 0;
      }
      img {
        display: block;
        width: 100%;
        margin: 0 auto;

        @media (min-width: $breakpointLg) {
          position: absolute;
          left: 50%;
          top: 50%;
          width: pxToRem(170);
          transform: translate(-50%, -50%);
        }
        &.logo-mobile {
          @media (min-width: $breakpointLg) {
            display: none;
          }
        }
        &.logo-desktop {
          display: none;
          @media (min-width: $breakpointLg) {
            display: block;
          }
        }
      }
    }
  }
}
