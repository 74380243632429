.order-success-box {
  background-color: $colorBoxBg;
  box-shadow: 0px 2px 2px $colorBoxShadow;
  border-radius: 2px;
  margin-bottom: 2rem;
  .box-header {
    background-color: #eeeeee;
    font-size: pxToRem(18);
    line-height: pxToRem(27);
    font-weight: bold;
    text-align: center;
    padding: 0.4rem 0;
    margin-bottom: 1rem;
  }
  .box-content {
    font-size: pxToRem(14);
    line-height: pxToRem(21);
    width: 80%;
    margin: 0 auto;
    padding-bottom: 1rem;
  }
}
