.product-selection-box-wrapper {
  margin-bottom: 3rem;
  font-family: $fontOpenSans;
  font-style: normal;
  color: $colorBlack;
  .product-selection-box {
    background: $colorBoxBg;
    box-shadow: 0px 2px 2px $colorBoxShadow;
    border-radius: 2px;
    .box-header {
      background-color: $colorBlack;
      color: $colorWhite;
      font-weight: 600;
      font-size: pxToRem(36);
      line-height: pxToRem(59);
      text-align: center;
      letter-spacing: 0.0675rem;
      padding: 0.6rem 0;
      margin-bottom: 2rem;
    }
    .box-content {
      color: $colorBlack;
      font-weight: bold;
      .separator {
        border-bottom: 1px solid #797979;
        height: 1px;
        margin-bottom: 2rem;
      }
      .product-container {
        margin-bottom: 2rem;
        padding: 0 1rem;
        .product {
          .num-days {
            font-size: pxToRem(18);
            line-height: pxToRem(27);
          }
          .price {
            font-size: pxToRem(32);
            line-height: 149.8%;
          }
          .desc {
            font-size: pxToRem(14);
            line-height: pxToRem(21);
          }
          .savings {
            font-size: pxToRem(14);
            line-height: pxToRem(29);
            color: $colorLightBlue;
          }
        }
        .qty {
          display: flex;
          justify-content: space-between;
          max-width: 10rem;
          margin: 0 auto;
          img {
            cursor: pointer;
          }
          span {
            font-size: pxToRem(32);
            line-height: pxToRem(48);
            text-align: center;
          }
        }
      }
    }
    .totals {
      font-weight: bold;
      text-align: center;
      .total-text {
        font-size: pxToRem(18);
        line-height: pxToRem(27);
      }
      .total-price {
        font-size: pxToRem(44);
        line-height: 3rem;
        &:after {
          content: ".00";
          font-size: pxToRem(18);
          line-height: pxToRem(27);
          vertical-align: top;
          top: 4px;
          left: 2px;
          position: relative;
        }
      }
    }
    .button {
      padding: 2rem 0;
      text-align: center;
      button {
        background-color: $colorRed;
        font-family: $fontRoboto;
        font-weight: bold;
        font-size: pxToRem(18);
        line-height: pxToRem(32);
        letter-spacing: 0.1rem;
        width: 80%;
        color: $colorWhite;
        padding: 1.1rem 0;
        border: 0;
      }
    }
    &.pass-activator {
      .box-content {
        width: 80%;
        margin: 0 auto;
      }
      .button {
        button {
          width: 100%;
        }
      }
    }
  }
}
