html {
  font-size: 100%;
}

body {
  margin: 0;
  font-family: $fontOpenSans, $fontRoboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $colorBlack;
  background-color: #e5e5e5;
}
