.form,
.buy-day-passes-box {
  &.activate-pass {
    width: 80%;
    margin: 0 auto;
  }
  font-size: pxToRem(14);
  line-height: pxToRem(21);
  h4 {
    &.link-pass {
      margin-top: 0.8rem;
    }
  }
  input[type="text"],
  input[type="email"],
  select {
    height: pxToRem(48);
    border: pxToRem(0.5) solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    background-color: $colorWhite;
    border-radius: 2px;
    width: 100%;
    padding-left: pxToRem(20);
    margin-bottom: pxToRem(14);
  }
  .terms {
    margin-top: 1.5rem;
    font-weight: normal;
    a {
      color: $colorBlack;
      text-decoration: underline;
    }
  }
  .button {
    margin-top: 1rem;
    button {
      width: 100%;
    }
  }
}
