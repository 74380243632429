// Colors
$colorBlack: #1a1a1a;
$colorWhite: #ffffff;
$colorRed: #d71921;
$colorLightBlue: #19c2f7;
$colorGrey: rgba(0, 0, 0, 0.5);
$colorBoxBg: #f7f7f7;
$colorBoxShadow: rgba(0, 0, 0, 0.15);

// Breakpoints
$breakpointSm: #{map-get($grid-breakpoints, sm)};
$breakpointMd: #{map-get($grid-breakpoints, md)};
$breakpointLg: #{map-get($grid-breakpoints, lg)};
$breakpointXl: #{map-get($grid-breakpoints, xl)};

// Fonts
$fontStagSans: "Stag Sans"; //"Stag Sans"
$fontOpenSans: "Open Sans";
$fontRoboto: "Roboto";
